import React, { useContext, useEffect, useRef, useState } from "react";
import logo from "../assets/image/logo.png";
import { Link, NavLink } from "react-router-dom";
import slugify from "react-slugify";
import { LanguageContext } from "../context/LangaugeContext";
import translations from "../data/langData";
const Headers = () => {
  const { language, changeLanguage } = useContext(LanguageContext);
  const t = translations[language];
  const [hamburger, setHamburger] = useState(false);
  const [localData, setLocalData] = useState([]);
  const cartRef = useRef(null);
  const updateLocalData = () => {
    const data = JSON.parse(localStorage.getItem("cart")) || [];
    setLocalData(data);
  };

  useEffect(() => {
    updateLocalData();
    const handleUpdateCart = () => {
      updateLocalData();
    };
    window.addEventListener("cartUpdated", handleUpdateCart);

    return () => {
      window.removeEventListener("cartUpdates", handleUpdateCart);
    };
  }, []);

  const hamburgerFunc = () => {
    setHamburger(!hamburger);
  };
  const [cartToggle, setCartToggle] = useState(false);
  const cart_toggle = () => {
    setCartToggle(!cartToggle);
  };

  const totalPrice = localData.reduce(
    (total, item) => total + item.price * item.quantity,
    0
  );

  const handleRemoveItem = (item, index) => {
    const remove_item = localData.filter((item, idx) => idx !== index);
    localStorage.setItem("cart", JSON.stringify(remove_item));
    window.dispatchEvent(new Event("cartUpdated"));
  };

  const handleLanguageChange = (event) => {
    const newLanguage = event.target.value;
    changeLanguage(newLanguage);
  };

  const handleClickOutside = (event) => {
    if (cartRef.current && !cartRef.current.contains(event.target)) {
      setCartToggle(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const [orderLocal, setOrderLocal] = useState({});

  useEffect(() => {
    const storedOrder = JSON.parse(localStorage.getItem("order")) || {};
    setOrderLocal(storedOrder);

    const hasRefreshed = localStorage.getItem("hasRefreshed");
    if (!hasRefreshed) {
      localStorage.setItem("hasRefreshed", "true");
      window.location.reload();
    }
  }, []);

  return (
    <>
      <div className="header-area">
        <div className="header-top bg-black">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 col-md-8 col-sm-6 col-6 ">
                <div className="top-left-wrap">
                  <ul className="phone-email-wrap">
                    <li>
                      <i className="fa fa-phone" /> *8989
                    </li>
                    <li className="header_icon">
                      <i className="fa fa-envelope-open-o " />{" "}
                      <a href="mailto:info@xor.az">info@xor.az</a>
                    </li>
                  </ul>
                  <ul className="link-top header_icon">
                    <li>
                      <a
                        href="https://www.instagram.com/xor.inc.az/"
                        target="_blank"
                        title="Instagram"
                      >
                        <i className="fa fa-instagram" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-6 col-6 order-1 order-lg-2">
                <div className="top-selector-wrapper">
                  <ul className="single-top-selector">
                    <li className="language list-inline-item">
                      <select
                        name=""
                        id="select"
                        value={language}
                        onChange={handleLanguageChange}
                      >
                        <option value="en">EN</option>
                        <option value="az">AZ</option>
                      </select>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="header-bottom-area header-sticky">
          <div className="container">
            <div className="row">
              <div className="col-lg-2 col-4">
                <div className="logo">
                  <NavLink to="/">
                    <img src={logo} alt="Xor logo" className="img-fluid w-75" />
                  </NavLink>
                </div>
              </div>
              <div className="col-lg-7 d-none d-lg-block">
                <div className="main-menu-area">
                  <nav className="main-navigation">
                    <ul>
                      <li className="active">
                        <NavLink to="/">{t.home}</NavLink>
                      </li>
                      <li>
                        <NavLink to="/collections">{t.collections} </NavLink>
                      </li>
                      <li>
                        <NavLink to="/about">{t.about}</NavLink>
                      </li>
                      <li>
                        <NavLink to="/blog">{t.blogs}</NavLink>
                      </li>
                      <li>
                        <NavLink to="/contact">{t.contact}</NavLink>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
              <div className="col-lg-3 col-8">
                <div className="header-bottom-right">
                  <div className="shoping-cart">
                    <div className="btn-group" ref={cartRef}>
                      <button className="dasd" onClick={() => cart_toggle()}>
                        <i className="fa fa-shopping-cart" /> {t.cart} (
                        {localData && localData.length > 0
                          ? localData.length
                          : 0}
                        )
                      </button>
                      <div
                        className={`dropdown-menu mini-cart-wrap ${
                          cartToggle ? "open" : ""
                        }`}
                      >
                        <div className="shopping-cart-content">
                          <ul className="mini-cart-content">
                            {localData &&
                              localData.map((item, index) => (
                                <li className="mini-cart-item">
                                  <div className="mini-cart-product-img">
                                    <a href="#">
                                      <img src={item.icon} alt />
                                    </a>
                                    <span className="product-quantity">
                                      {item.quantity}x
                                    </span>
                                  </div>
                                  <div className="mini-cart-product-desc">
                                    <h3>
                                      <Link
                                        to={`/${slugify(
                                          `${item.name_show}-${item.memory +
                                            "GB"}-${item.value}`
                                        ).toLowerCase()}`}
                                        style={{ fontSize: "small" }}
                                      >
                                        {item.name_show.split(" (")[0]}{" "}
                                        {item.value}
                                      </Link>
                                    </h3>
                                    <div className="price-box">
                                      <span className="new-price">
                                        {item.price} ₼{" "}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="remove-from-cart">
                                    <a
                                      href="#"
                                      title="Remove"
                                      onClick={() =>
                                        handleRemoveItem(item, index)
                                      }
                                    >
                                      <i className="fa fa-trash" />
                                    </a>
                                  </div>
                                </li>
                              ))}

                            <>
                              {localData.length == 0 ? (
                                <div>
                                  <p>{t.empty}</p>
                                  {orderLocal &&
                                    Object.keys(orderLocal).length > 0 && (
                                      <>
                                        <hr />
                                        <NavLink to="/order">
                                          <p>Sifarişiniz</p>
                                        </NavLink>
                                      </>
                                    )}
                                </div>
                              ) : (
                                <>
                                  <li>
                                    <div className="shopping-cart-total">
                                      <h4>
                                        {t.total} :{" "}
                                        <span>{totalPrice.toFixed(1)} ₼</span>
                                      </h4>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="shopping-cart-btn">
                                      <NavLink to="/checkout">
                                        {t.checkout}
                                      </NavLink>
                                    </div>
                                  </li>
                                </>
                              )}
                            </>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="mobile-menu d-block d-lg-none mean-container">
                  <div class="mean-bar">
                    <a
                      onClick={() => hamburgerFunc()}
                      href="#nav"
                      class={`meanmenu-reveal ${
                        hamburger ? "meanmenu-close" : ""
                      }`}
                      style={{
                        right: 0,
                        left: "auto",
                        textAlign: "center",
                        textIndent: 0,
                        fontSize: 0,
                      }}
                    >
                      <span
                        class={`menu-${hamburger ? "close" : "bar"}`}
                      ></span>
                    </a>
                    <nav class="mean-nav">
                      <ul
                        style={{
                          display: `${hamburger ? "block" : "none"}`,
                          transition: "250ms !important",
                        }}
                      >
                        <li>
                          <NavLink to="/" onClick={() => setHamburger(false)}>
                            {t.home}
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to="/collections"
                            onClick={() => setHamburger(false)}
                          >
                            {t.collections}
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to="/blog"
                            onClick={() => setHamburger(false)}
                          >
                            {t.blogs}
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to="/about"
                            onClick={() => setHamburger(false)}
                          >
                            {t.about}
                          </NavLink>
                        </li>
                        <li class="mean-last">
                          <NavLink
                            to="/contact"
                            onClick={() => setHamburger(false)}
                          >
                            {t.contact}
                          </NavLink>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Headers;
