import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import BreadCrumb from "../others/BreadCrumb";
import about_img from "../assets/image/about.jpg";
import { addItem } from "../others/addtocart";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation } from "swiper/modules";
import { NavLink } from "react-router-dom";
import slugify from "react-slugify";
import axios from "axios";
import { Toaster, toast } from "sonner";
import translations from "../data/langData";
import { LanguageContext } from "../context/LangaugeContext";
const About = () => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];

  const [selectedData, setSelectedData] = useState();
  const [toggle, setToggle] = useState(false);
  const [data, setData] = useState([]);
  const [quantity, setQuantity] = useState(1);

  const handleToggle = (item) => {
    setSelectedData(item);
    setToggle(!toggle);
  };

  const decreaseQuantity = () => {
    setQuantity((prevQuantity) => prevQuantity + 1);
  };

  const increaseQuantity = () => {
    if (quantity > 1) {
      setQuantity((prevQuantity) => prevQuantity - 1);
    }
  };
  useEffect(() => {
    axios
      .get("https://myshops.az/apk/app_prod.php?q=xor")
      .then((res) => {
        const data = res.data;
        setData(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const [sentencesHtml, setSentencesHtml] = useState('');

  useEffect(() => {
   if (selectedData) {
     const description = language == "en" ? selectedData.description_ru : selectedData.description_az;
     const sentences = description.split(/(?<=\.)/).filter(sentence => sentence.trim() !== '');
     const numberOfSentencesToShow = 1; 
     const selectedSentences = sentences.slice(0, numberOfSentencesToShow);
     const sentencesHtml = selectedSentences.join(' ');
     setSentencesHtml(sentencesHtml);
   }
  }, [selectedData,language]);
  return (
    <>
      <Helmet>
        <title>{t.about}</title>
      </Helmet>
      <BreadCrumb title={t.about} />
      <div className="content-wraper">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="about-us-img">
                <img src={about_img} alt="" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="about-info-wrapper">
                <h2>{t.xorAz}</h2>
                <p>
                {t.aboutdesc1}
                </p>
                <p>
                {t.aboutdesc2}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="our-team-area pt-5">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-title">
              <h2>{t.collections}</h2>
              <p>{t.luxury}</p>
            </div>
          </div>
        </div>
      </div>
      <div
        className="container collections_slider"
        style={{ paddingBottom: "60px" }}
      >
        <Swiper
          slidesPerView={1}
          spaceBetween={3}
          navigation={true}
          loop={true}
          breakpoints={{
            640: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 4,
              spaceBetween: 40,
            },
            1024: {
              slidesPerView: 4,
              spaceBetween: 3,
            },
          }}
          modules={[Navigation]}
          className="mySwiper"
        >
          <Toaster position="top-right" />
          <div id="grid-view" className="tab-pane active">
            <div className="shop-product-area">
              <div className="row">
                {data.map((item) => (
                  <SwiperSlide key={item.id}>
                    <div className="col-lg-12 col-md-4 col-sm-6 mt-30">
                      <div className="single-product-wrap">
                        <div className="product-image">
                          <NavLink
                            to={`/${slugify(
                              `${item.name_show}-${item.memory + "GB"}-${
                                item.value
                              }`
                            ).toLowerCase()}`}
                            onClick={() => window.scrollTo({ top: 0 })}
                          >
                            <img src={item.icon} alt="" />
                          </NavLink>
                          <div className="quick_view">
                            <a
                              href="#"
                              title="quick view"
                              className="quick-view-btn"
                              data-bs-toggle="modal"
                              data-bs-target="#exampleModalCenter"
                              onClick={() => handleToggle(item)}
                            >
                              <i className="fa fa-search"></i>
                            </a>
                          </div>
                        </div>
                        <div className="product-content">
                          <h3>
                            <NavLink
                              to={`/${slugify(
                                `${item.name_show}-${item.memory + "GB"}-${
                                  item.value
                                }`
                              ).toLowerCase()}`}
                              onClick={() => window.scrollTo({ top: 0 })}
                            >
                              {item.name_show.split(' (')[0]} {item.value}
                            </NavLink>
                          </h3>
                          <div className="price-box">
                            <span className="new-price">{item.price} ₼</span>
                          </div>
                          <div className="product-action">
                            <button
                              className="add-to-cart"
                              title="Add to cart"
                              onClick={() => {
                                addItem(item);
                                toast.success(`${item.name_show}`, { className: "py-3 px-2", duration: 600 });
                              }}
                            >
                              <i className="fa fa-plus"></i> {t.added}
                            </button>
                            <div className="star_content">
                              <ul className="d-flex">
                                <li>
                                  <a className="star" href="#">
                                    <i className="fa fa-star"></i>
                                  </a>
                                </li>
                                <li>
                                  <a className="star" href="#">
                                    <i className="fa fa-star"></i>
                                  </a>
                                </li>
                                <li>
                                  <a className="star" href="#">
                                    <i className="fa fa-star"></i>
                                  </a>
                                </li>
                                <li>
                                  <a className="star" href="#">
                                    <i className="fa fa-star"></i>
                                  </a>
                                </li>
                                <li>
                                  <a className="star" href="#">
                                    <i className="fa fa-star"></i>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </div>
            </div>
          </div>
        </Swiper>
      </div>
      <div
        className={`modal fade modal-wrapper ${toggle ? "show" : ""}`}
        id="exampleModalCenter"
        style={{ display: `${toggle ? "block" : ""}`, padding: "0" }}
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
              <div className="modal-inner-area row">
                <div className="col-lg-5 col-md-6 col-sm-6">
                  <div className="product-details-left">
                    <div className="product-details-images slider-navigation-1">
                      <div className="lg-image">
                        <img
                          src={selectedData && selectedData.icon}
                          alt="product image"
                        />
                      </div>
                    </div>
                    {/* <div className="product-details-thumbs slider-thumbs-1">										
                <div className="sm-image"><img src="assets/images/product/1.jpg" alt="product image thumb" /></div>
              </div> */}
                  </div>
                </div>
                <div className="col-lg-7 col-md-6 col-sm-6">
                  <div className="product-details-view-content">
                    <div className="product-info">
                      <h3>
                        {selectedData && selectedData.name_show.split(' (')[0]}{" "}
                        {selectedData && selectedData.value}{" "}
                      </h3>
                      <div className="price-box">
                        {/* <span className="old-price">$70.00</span> */}
                        <span className="new-price">
                          {selectedData && selectedData.price} ₼
                        </span>
                        <span className="old-price">
                          {selectedData && selectedData.taksit} ₼
                        </span>
                      </div>
                      <p dangerouslySetInnerHTML={{__html:sentencesHtml}}/>
                      <div className="product-variants">
                        <div className="produt-variants-color">
                          <label>{t.color}</label>
                          <ul className="color-list">
                            <li>
                              <a
                                href="#"
                                className="orange-color active"
                                style={{
                                  background: `#${
                                    selectedData && selectedData.color_code
                                  }`,
                                }}
                              />
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="single-add-to-cart">
                        <div className="cart-quantity">
                          <div className="quantity">
                            <label>{t.quantity}</label>
                            <div className="cart-plus-minus">
                              <input
                                className="cart-plus-minus-box"
                                value={quantity}
                                type="text"
                              />
                              <div
                                className="dec qtybutton"
                                onClick={increaseQuantity}
                              >
                                <i className="fa fa-angle-down" />
                              </div>
                              <div
                                className="inc qtybutton"
                                onClick={decreaseQuantity}
                              >
                                <i className="fa fa-angle-up" />
                              </div>
                            </div>
                          </div>
                          <button
                            className="add-to-cart"
                            onClick={() => {
                              addItem(selectedData, quantity)
                              toast.success(`${selectedData.name_show}`, { className: "py-3 px-2", duration: 600 });
                            }}
                            type="submit"
                          >
                            {t.added}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
