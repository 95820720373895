import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import { LanguageContext } from "../context/LangaugeContext";
import translations from "../data/langData";

const BreadCrumb = ({title}) => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  return (
    <>
      <div className="breadcrumb-area bg-grey">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <ul className="breadcrumb-list">
                <li className="breadcrumb-item">
                  <NavLink to="/">{t.home}</NavLink>
                </li>
                <li className="breadcrumb-item active">{title}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BreadCrumb;
