import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { blogdata } from '../data/blogdata';
import slugify from 'react-slugify';
import BreadCrumb from '../others/BreadCrumb';
import { LanguageContext } from '../context/LangaugeContext';
import translations from '../data/langData';
import xor1 from "../assets/image/xor4.webp";
import xor2 from "../assets/image/xor5.webp";
import xor3 from "../assets/image/xor6.webp";
import PreLoader from "../others/PreLoader";
import { Helmet } from 'react-helmet';

const BlogDetails = () => {
    const { blogtitle } = useParams();
    const { language } = useContext(LanguageContext);
    const t = translations[language];
    const [findData, setFindData] = useState(null);

    useEffect(() => {
        const data = blogdata.find((i) => slugify(language === "en" ? i.title1 : i.title) === blogtitle);
        setFindData(data);
    }, [language, blogtitle]);

    return (
        <>
            <Helmet>
                <title>{`${language === "en" && findData ? findData.title1 : findData && findData.title}`}</title>
            </Helmet>
            <BreadCrumb title={`${language === "en" && findData ? findData.title1 : findData && findData.title}`} />
            {
                !findData ? <PreLoader /> :
                <div className="content-wraper">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-10 order-lg-2 order-1">
                                <div className="blog-details-wrapper">
                                    <div className="blog-details-image">
                                        <div className="d-flex justify-content-between">
                                            <span style={{ fontWeight: "500", opacity: "0.7" }}>{t.xorAz}</span>
                                            <span style={{ fontWeight: "500", opacity: "0.7" }}>24.07.2024</span>
                                        </div>
                                        <img src={findData.img} alt="blog image" />
                                    </div>
                                    <div className="postinfo-wrapper">
                                        <div className="post-info">
                                            <h1 style={{ fontSize: "30px" }}>{t.blog_title}</h1>
                                            <p className='mt-4'>{t.blogdesc1}</p>
                                            <blockquote className="blockquote-inner">
                                                <p>{t.blogtitle1}</p>
                                            </blockquote>
                                            <p>{t.blogdesc2}</p>
                                            <img src={findData.img1} alt="" className="img-fluid" />
                                            <blockquote className="blockquote-inner">
                                                <p>{t.blogtitle2}</p>
                                            </blockquote>
                                            <p>{t.blogdesc3}</p>
                                            <blockquote className="blockquote-inner">
                                                <p>{t.blogtitle3}</p>
                                            </blockquote>
                                            <p>{t.blogdesc4}</p>
                                            <img src={findData.img2} alt="" className="img-fluid" />
                                            <blockquote className="blockquote-inner">
                                                <p>{t.blogtitle4}</p>
                                            </blockquote>
                                            <p>{t.blogdesc5}</p>
                                            <p>{t.blogdesc6}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="container mt-4">
                                    <div className="row">
                                        {
                                            [xor1, xor2, xor3].map((i, index) => (
                                                <div key={index} className="col-lg-4 col-md-6 col-6">
                                                    <img src={i} alt="" className="img-fluid" />
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
}

export default BlogDetails;
