import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "../components/home/Home";
import Headers from "../components/Headers";
import Footer from "../components/Footer";
import Contact from "../pages/Contact";
import About from "../pages/About";
import Collections from "../pages/Collections";
import CollectionsDetails from "../pages/CollectionsDetails";
import Checkout from "../pages/Checkout";
import ScrollToTop from "react-scroll-to-top";
import icon from "../assets/image/icon.png"
import Blog from "../pages/Blog";
import BlogDetails from "../pages/BlogDetails";
import Order from "../pages/Order";
const AppRouter = () => {
  return (
    <>
      <BrowserRouter>
        <Headers />
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/contact" element={<Contact />}></Route>
          <Route path="/about" element={<About />}></Route>
          <Route path="/collections" element={<Collections />}></Route>
          <Route path="/checkout" element={<Checkout />}></Route>
          <Route path="/blog" element={<Blog />}></Route>
          <Route path="/order" element={<Order />}></Route>
          <Route path="/:title" element={<CollectionsDetails />}></Route>
          <Route path="/blog/:blogtitle" element={<BlogDetails />}></Route>
        </Routes>
        <Footer />
        <ScrollToTop
          top={300}
          className="scrollToTop"
          component={<div><img src={icon} alt="" className="img-fluid" style={{width:"80%"}} /></div>}
        />
      </BrowserRouter>
    </>
  );
};

export default AppRouter;
