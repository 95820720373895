import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import BreadCrumb from "../others/BreadCrumb";
import { NavLink } from "react-router-dom";
import slugify from "react-slugify";
import { addItem } from "../others/addtocart";
import axios from "axios";
import PreLoader from "../others/PreLoader";
import { Toaster,toast } from "sonner";
import { LanguageContext } from "../context/LangaugeContext";
import translations from "../data/langData";
const Collections = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  useEffect(() => {
    axios
      .get("https://myshops.az/apk/app_prod.php?q=xor")
      .then((res) => {
        const data = res.data;
        setData(data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }, []);

  const [selectedData, setSelectedData] = useState();
  const [toggle, setToggle] = useState(false);

  const [quantity, setQuantity] = useState(1);

  const handleToggle = (item) => {
    setSelectedData(item);
    setToggle(!toggle);
  };
  const handleSelect = (e) => {
    const selected = e.target.value;

    let sortedData;

    if (selected === "asc") {
      sortedData = [...data].sort((a, b) => {
        const titleA = a.name_show ? a.name_show.toUpperCase() : "";
        const titleB = b.name_show ? b.name_show.toUpperCase() : "";
        return titleA.localeCompare(titleB);
      });
    } else if (selected === "desc") {
      sortedData = [...data].sort((a, b) => {
        const titleA = a.name_show ? a.name_show.toUpperCase() : "";
        const titleB = b.name_show ? b.name_show.toUpperCase() : "";
        return titleB.localeCompare(titleA);
      });
    }
    if (selected === "price-asc") {
      sortedData = [...data].sort((a, b) => a.price - b.price);
    } else if (selected === "price-desc") {
      sortedData = [...data].sort((a, b) => b.price - a.price);
    } else {
    }
    setData(sortedData);
    setData(sortedData);
  };

  const decreaseQuantity = () => {
    setQuantity((prevQuantity) => prevQuantity + 1);
  };
  const increaseQuantity = () => {
    if (quantity > 1) {
      setQuantity((prevQuantity) => prevQuantity - 1);
    }
  };
  
const [sentencesHtml, setSentencesHtml] = useState('');
const [sentencesHtml1, setSentencesHtml1] = useState('');
const change_desc =(item)=>{
  const description = language == "en" ? item.description_ru : item.description_az;
   const sentences = description.split(/(?<=\.)/).filter(sentence => sentence.trim() !== '');
   const numberOfSentencesToShow = 3; 
   const selectedSentences = sentences.slice(0, numberOfSentencesToShow);
   const sentencesHtml = selectedSentences.join(' ');
   return sentencesHtml;
}
useEffect(() => {

 if (selectedData) {
   const description = language == "en" ? selectedData.description_ru : selectedData.description_az;
   const sentences = description.split(/(?<=\.)/).filter(sentence => sentence.trim() !== '');
   const numberOfSentencesToShow = 1; 
   const selectedSentences = sentences.slice(0, numberOfSentencesToShow);
   const sentencesHtml = selectedSentences.join(' ');
   setSentencesHtml(sentencesHtml);
  } else {
   const description = language == "en" ? data.description_ru : data.description_az;
   setSentencesHtml1(description)
 }
}, [selectedData,language]);
  return (
    <>
      <Helmet>
        <title>{t.collections}</title>
      </Helmet>
      <BreadCrumb title={t.collections} />
      {loading ? (
        <PreLoader />
      ) : (
        <>
          <div class="content-wraper">
            <div class="container">
              <div class="row">
                <div class="col-lg-12">
                  <div class="shop-top-bar">
                    <div class="shop-bar-inner">
                      <div class="product-view-mode">
                        <ul class="nav shop-item-filter-list" role="tablist">
                          <li class="active">
                            <a
                              class="active"
                              data-bs-toggle="tab"
                              href="#grid-view"
                            >
                              <i class="fa fa-th"></i>
                            </a>
                          </li>
                          <li>
                            <a data-bs-toggle="tab" href="#list-view">
                              <i class="fa fa-th-list"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div class="toolbar-amount">
                        <span>{data.length} {t.result}</span>
                      </div>
                    </div>

                    <div class="product-select-box d-flex justify-content-between">
                      {/* <div class="product-short me-3" style={{width:"100px"}}>
                                <p>View:</p>
                                <select class="nice-select">
                                    <option value="6">6</option>
                                    <option value="10">10</option>
                                    <option value="15">15</option>
                                </select>
                            </div> */}
                      <div class="product-short">
                        <p>{t.sortby}:</p>
                        <select
                          class="nice-select"
                          onChange={(e) => handleSelect(e)}
                        >
                          <option value="asc">{t.sort_name} (A - Z)</option>
                          <option value="desc">{t.sort_name} (Z - A)</option>
                          <option value="price-asc">
                            {t.sort_price} ({t.price_low} &gt; {t.price_high})
                          </option>
                          <option value="price-desc">
                            {t.sort_price} ({t.price_high} &gt; {t.price_low})
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="shop-products-wrapper">
                    <div class="tab-content">
                      <div id="grid-view" class="tab-pane active">
                        <div class="shop-product-area">
                          <div class="row">
                            {data.map((item) => (
                              <div class="col-lg-4 col-md-4 col-sm-6 mt-30">
                                <div class="single-product-wrap">
                                  <div class="product-image">
                                    <NavLink
                                      to={`/${slugify(
                                        `${item.name_show}-${
                                          item.memory + "GB"
                                        }-${item.value}`
                                      ).toLowerCase()}`}
                                      onClick={() =>
                                        window.scrollTo({ top: 0 })
                                      }
                                    >
                                      <img src={item.icon} alt="" />
                                    </NavLink>
                                    <div class="quick_view">
                                      <a
                                        href="#"
                                        title="quick view"
                                        onClick={() => handleToggle(item)}
                                        class="quick-view-btn"
                                        data-bs-toggle="modal"
                                        data-bs-target="#exampleModalCenter"
                                      >
                                        <i class="fa fa-search"></i>
                                      </a>
                                    </div>
                                  </div>
                                  <div class="product-content">
                                    <h3>
                                      <NavLink
                                        to={`/${slugify(
                                          `${item.name_show}-${
                                            item.memory + "GB"
                                          }-${item.value}`
                                        ).toLowerCase()}`}
                                        onClick={() =>
                                          window.scrollTo({ top: 0 })
                                        }
                                      >
                                     {item.name_show.split(' (')[0]}   {item.value}
                                      </NavLink>
                                    </h3>
                                    <div class="price-box">
                                      <span class="new-price">
                                        {item.price} ₼
                                      </span>
                                      <span
                                        class="old-price"
                                        style={{ opacity: "0.6" }}
                                      >
                                        {item.taksit} ₼
                                      </span>
                                    </div>
                                      <Toaster  position="top-right"/>
                                    <div class="product-action">
                                      <button
                                        class="add-to-cart"
                                        onClick={() => {
                                          addItem(item)
                                          toast.success(`${item.name_show}`, { className: "py-3 px-2", duration: 600 })
                                        }}
                                        title="Add to cart"
                                      >
                                        <i class="fa fa-plus"></i>   {t.added}
                                      </button>
                                      <div class="star_content">
                                        <ul class="d-flex">
                                          <li>
                                            <a class="star" href="#">
                                              <i class="fa fa-star"></i>
                                            </a>
                                          </li>
                                          <li>
                                            <a class="star" href="#">
                                              <i class="fa fa-star"></i>
                                            </a>
                                          </li>
                                          <li>
                                            <a class="star" href="#">
                                              <i class="fa fa-star"></i>
                                            </a>
                                          </li>
                                          <li>
                                            <a class="star" href="#">
                                              <i class="fa fa-star"></i>
                                            </a>
                                          </li>
                                          <li>
                                            <a class="star" href="#">
                                              <i class="fa fa-star"></i>
                                            </a>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                      <div id="list-view" class="tab-pane">
                        <div class="row">
                          <div class="col">
                            {data.map((item) => (
                              <div class="row product-layout-list">
                                <div class="col-lg-4 col-md-5">
                                  <div class="single-product-wrap">
                                    <div
                                      class="product-image"
                                      style={{ background: "Red" }}
                                    >
                                      <NavLink
                                        to={`/${slugify(
                                          `${item.name_show}-${
                                            item.memory + "GB"
                                          }-${item.value}`
                                        ).toLowerCase()}`}
                                        onClick={() =>
                                          window.scrollTo({ top: 0 })
                                        }
                                      >
                                        <img src={item.icon} alt="" />
                                      </NavLink>
                                      <div class="quick_view">
                                        <a
                                          href="#"
                                          title="quick view"
                                          class="quick-view-btn"
                                          onClick={() => handleToggle(item)}
                                          data-bs-toggle="modal"
                                          data-bs-target="#exampleModalCenter"
                                        >
                                          <i class="fa fa-search"></i>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-lg-8 col-md-7">
                                  <div class="product_desc">
                                    <div class="product-content-list">
                                      <h3>
                                        <NavLink
                                          to={`/${slugify(
                                            `${item.name_show}-${
                                              item.memory + "GB"
                                            }-${item.value}`
                                          ).toLowerCase()}`}
                                          onClick={() =>
                                            window.scrollTo({ top: 0 })
                                          }
                                        >
                                          {item.name_show.split(' (')[0]} {item.value}
                                        </NavLink>
                                      </h3>
                                      <div class="star_content">
                                        <ul class="d-flex">
                                          <li>
                                            <a class="star" href="#">
                                              <i class="fa fa-star"></i>
                                            </a>
                                          </li>
                                          <li>
                                            <a class="star" href="#">
                                              <i class="fa fa-star"></i>
                                            </a>
                                          </li>
                                          <li>
                                            <a class="star" href="#">
                                              <i class="fa fa-star"></i>
                                            </a>
                                          </li>
                                          <li>
                                            <a class="star" href="#">
                                              <i class="fa fa-star"></i>
                                            </a>
                                          </li>
                                          <li>
                                            <a class="star" href="#">
                                              <i class="fa fa-star"></i>
                                            </a>
                                          </li>
                                        </ul>
                                      </div>
                                      <div class="price-box">
                                        <span class="new-price">
                                          {item.price} ₼
                                        </span>
                                        <span
                                          class="old-price"
                                          style={{ opacity: "0.6" }}
                                        >
                                          {item.taksit} ₼
                                        </span>
                                      </div>
                                     <p dangerouslySetInnerHTML={{__html :change_desc (item)}}/>
                                      <Toaster position="top-right"/>
                                      <button
                                        class="add-to-cart"
                                        title="Add to cart"
                                        onClick={() => {
                                          addItem(item)
                                          toast.success(`${item.name_show}`, { className: "py-3 px-2", duration: 600 })
                                        }}
                                      >
                                        <i class="fa fa-plus"></i> {t.added}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>

                      <div class="paginatoin-area">
                        <div class="row justify-content-end">
                          <div class="col-lg-6 col-md-6">
                            <ul class="pagination-box">
                              <li>
                                <a href="#" class="Previous">
                                  <i class="fa fa-chevron-left"></i> {t.previous}
                                </a>
                              </li>
                              <li class="active">
                                <a href="#">1</a>
                              </li>
                              <li>
                                <a href="#" class="Next">
                                  {" "}
                                  {t.next} <i class="fa fa-chevron-right"></i>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className={`modal fade modal-wrapper ${toggle ? "show" : ""}`}
            id="exampleModalCenter"
            style={{ display: `${toggle ? "block" : ""}`, padding: "0" }}
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-body">
                  <button
                    type="button"
                    className="close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                  <div className="modal-inner-area row">
                    <div className="col-lg-5 col-md-6 col-sm-6">
                      <div className="product-details-left">
                        <div className="product-details-images slider-navigation-1">
                          <div className="lg-image">
                            <img
                              src={selectedData && selectedData.icon}
                              alt="product image"
                            />
                          </div>
                        </div>
                        {/* <div className="product-details-thumbs slider-thumbs-1">										
                <div className="sm-image"><img src="assets/images/product/1.jpg" alt="product image thumb" /></div>
              </div> */}
                      </div>
                    </div>
                    <div className="col-lg-7 col-md-6 col-sm-6">
                      <div className="product-details-view-content">
                        <div className="product-info">
                          <h3>
                            {selectedData && selectedData.name_show.split(' (')[0]}{" "}
                            {selectedData && selectedData.value}{" "}
                          </h3>
                          <div className="price-box">
                            <span className="new-price">
                              {selectedData && selectedData.price} ₼
                            </span>
                            <span className="old-price">
                              {selectedData && selectedData.taksit} ₼
                            </span>
                          </div>
                          <p dangerouslySetInnerHTML={{__html:sentencesHtml}}/>
                          <div className="product-variants">
                            <div className="produt-variants-color">
                              <label>{t.color}</label>
                              <ul className="color-list">
                                <li>
                                  <a
                                    href="#"
                                    className="orange-color active"
                                    style={{
                                      background: `#${
                                        selectedData && selectedData.color_code
                                      }`,
                                    }}
                                  />
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="single-add-to-cart">
                            <div className="cart-quantity">
                              <div className="quantity">
                                <label>{t.quantity}</label>
                                <div className="cart-plus-minus">
                                  <input
                                    className="cart-plus-minus-box"
                                    value={quantity}
                                    type="text"
                                  />
                                  <div
                                    className="dec qtybutton"
                                    onClick={increaseQuantity}
                                  >
                                    <i className="fa fa-angle-down" />
                                  </div>
                                  <div
                                    className="inc qtybutton"
                                    onClick={decreaseQuantity}
                                  >
                                    <i className="fa fa-angle-up" />
                                  </div>
                                </div>
                              </div>
                              <Toaster  position="top-right"/>
                              <button
                                className="add-to-cart"
                                onClick={() => {
                                  addItem(selectedData, quantity)
                                  toast.success(`${selectedData && selectedData.name_show}`, { className: "py-3 px-2", duration: 600 })
                                }}
                                type="submit"
                                
                              >
                                {t.added}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Collections;
