import React from 'react'

const PreLoader = () => {
  return (
    <>
      <div className="preload preload-container">
        <div className="middle" />
      </div>
    </>
  )
}

export default PreLoader
