import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import BreadCrumb from "../others/BreadCrumb";
import { useParams } from "react-router-dom";
import slugify from "react-slugify";
import { addItem } from "../others/addtocart";
import axios from "axios";
import PreLoader from "../others/PreLoader";
import { Toaster, toast } from "sonner";
import { LanguageContext } from "../context/LangaugeContext";
import translations from "../data/langData";
import ImageGallery from "react-image-gallery";

const CollectionsDetails = () => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [quantity, setQuantity] = useState(1);
  const { title } = useParams();

  const [detailsData, setDetailsData] = useState([]);

  useEffect(() => {
    axios
      .get("https://myshops.az/apk/app_prod.php?q=xor")
      .then((res) => {
        const data = res.data;
        setData(data);
        const findData = data.find(
          (item) =>
            slugify(
              slugify(`${item.name_show}-${item.memory + "GB"}-${item.value}`)
            ).toLowerCase() === title
        );
        if (findData) {
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [title]);

  const findData = data.find(
    (item) =>
      slugify(
        slugify(`${item.name_show}-${item.memory + "GB"}-${item.value}`)
      ).toLowerCase() === title
  );
  useEffect(() => {
    axios
      .get(`https://myshops.az/apk/app_view.php?id=${findData && findData.id}`)
      .then((res) => {
        const allData = res.data;
        setDetailsData(allData);
      })
      .catch((err) => {
        console.log("XETA :", err);
      });
  }, [findData]);

  const decreaseQuantity = () => {
    setQuantity((prevQuantity) => prevQuantity + 1);
  };

  const increaseQuantity = () => {
    if (quantity > 1) {
      setQuantity((prevQuantity) => prevQuantity - 1);
    }
  };

  const [sentencesHtml, setSentencesHtml] = useState("");

  useEffect(() => {
    if (findData) {
      const description =
        language == "en" ? findData.description_ru : findData.description_az;
      const sentences = description
        .split(/(?<=\.)/)
        .filter((sentence) => sentence.trim() !== "");
      const numberOfSentencesToShow = 3;
      const selectedSentences = sentences.slice(0, numberOfSentencesToShow);
      const sentencesHtml = selectedSentences.join(" ");
      setSentencesHtml(sentencesHtml);
    }
  }, [findData, language]);
  useEffect(() => {
    const metaTitle = document.createElement("meta");
    metaTitle.name = "og:title";
    metaTitle.content = "Dinamik Başlık";
    document.head.appendChild(metaTitle);

    const metaImage = document.createElement("meta");
    metaImage.name = "og:image";
    metaImage.content = "https://example.com/image.jpg";
    document.head.appendChild(metaImage);
  }, []);
  return (
    <>
      {loading ? (
        <PreLoader />
      ) : (
        findData && (
          <>
            <Helmet>
              <title>{`${findData.name_show}-${findData.memory +
                "GB"}-${findData.price + "₼"}`}</title>
              <meta property="og:image" content={findData.icon} />
              <meta property="og:url" content={window.location.href} />
            </Helmet>
            <BreadCrumb title={`${findData.name_show}-${findData.value}`} />

            <div className="content-wraper">
              <div className="container">
                <div className="row single-product-area">
                  <div className="col-lg-6 col-md-6">
                    <div className="product-details-left ">
                      <div className="product-details-images-2 slider-lg-image-2 detailsImg">
                        <div className="lg-image ">
                          <img
                            src={findData.icon}
                            alt=""
                            className="img-fluid"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="product-details-view-content">
                      <div className="product-info">
                        <h2>
                          {findData.name_show.split(" (")[0]} {findData.value}
                        </h2>
                        <div className="price-box">
                          <span className="new-price">{findData.price} ₼</span>
                          <span className="old-price">{findData.taksit} ₼</span>
                        </div>
                        <div class="star_content">
                          <ul class="d-flex">
                            <li>
                              <a class="star" href="#">
                                <i class="fa fa-star"></i>
                              </a>
                            </li>
                            <li>
                              <a class="star" href="#">
                                <i class="fa fa-star"></i>
                              </a>
                            </li>
                            <li>
                              <a class="star" href="#">
                                <i class="fa fa-star"></i>
                              </a>
                            </li>
                            <li>
                              <a class="star" href="#">
                                <i class="fa fa-star"></i>
                              </a>
                            </li>
                            <li>
                              <a class="star" href="#">
                                <i class="fa fa-star"></i>
                              </a>
                            </li>
                          </ul>
                        </div>
                        <p>{t.cat} : XOR</p>

                        <p
                          dangerouslySetInnerHTML={{ __html: sentencesHtml }}
                        />
                        <div className="product-variants">
                          <div className="produt-variants-color">
                            <label>{t.color}</label>
                            <ul className="color-list">
                              <li className="d-flex">
                                {detailsData &&
                                  detailsData.color?.map((i) => (
                                    <a
                                      href="#"
                                      className={`active ${
                                        detailsData.color.length > 1
                                          ? "mx-1"
                                          : ""
                                      }`}
                                      style={{
                                        background: `#${i.code}`,
                                        border: "2px solid #000",
                                      }}
                                    />
                                  ))}
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="single-add-to-cart">
                          <div className="cart-quantity">
                            <div className="quantity">
                              <label>{t.quantity}</label>
                              <div className="cart-plus-minus">
                                <input
                                  className="cart-plus-minus-box"
                                  value={quantity}
                                  type="text"
                                />
                                <div
                                  className="dec qtybutton"
                                  onClick={increaseQuantity}
                                >
                                  <i className="fa fa-angle-down" />
                                </div>
                                <div
                                  className="inc qtybutton"
                                  onClick={decreaseQuantity}
                                >
                                  <i className="fa fa-angle-up" />
                                </div>
                              </div>
                            </div>
                            <Toaster position="top-right" />
                            <button
                              className="add-to-cart"
                              onClick={() => {
                                addItem(findData, quantity);
                                toast.success(
                                  `${findData.name_show}-${findData.memory +
                                    "GB"}`,
                                  { className: "py-3 px-2", duration: 600 }
                                );
                              }}
                              type="submit"
                            >
                              {t.added}
                            </button>
                          </div>
                        </div>
                        <div className="product-availability">
                          <i className="fa fa-check" /> {findData.rest_az}
                        </div>
                        <div className="product-social-sharing">
                          <label>{t.share}</label>
                          <ul>
                            <li>
                              <a href="#">
                                <i className="fa fa-facebook" />
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i className="fa fa-twitter" />
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i className="fa fa-google-plus" />
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i className="fa fa-pinterest" />
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="product-details-tab mt--60">
                      <ul role="tablist" className="mb--50 nav d-flex">
                        <li role="presentation">
                          <a
                            data-bs-toggle="tab"
                            role="tab"
                            href="#sheet"
                            className="active"
                          >
                            {t.desc}
                          </a>
                        </li>
                        <li className="active" role="presentation">
                          <a
                            data-bs-toggle="tab"
                            role="tab"
                            href="#description"
                          >
                            {t.details}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="product_details_tab_content tab-content">
                      <div
                        className="product_tab_content tab-pane active"
                        id="sheet"
                        role="tabpanel"
                      >
                        <div
                          className="pro_feature"
                          dangerouslySetInnerHTML={{
                            __html:
                              language == "en"
                                ? findData.description_ru
                                : findData.description_az,
                          }}
                        />
                      </div>
                      <div
                        className="product_tab_content tab-pane"
                        id="description"
                        role="tabpanel"
                      >
                        <div className="product_description_wrap">
                          <table className="collections_table">
                            {detailsData &&
                              detailsData.properties?.map((i) => (
                                <tr>
                                  <th className="text-start ps-3 py-3">
                                    {i.name_az}
                                  </th>
                                  <th>{i.value_az}</th>
                                </tr>
                              ))}
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )
      )}
    </>
  );
};

export default CollectionsDetails;
