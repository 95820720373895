import React, { useContext, useEffect, useState } from "react";
import { LanguageContext } from "../context/LangaugeContext";
import translations from "../data/langData";

const Order = () => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  const [orderLocal, setOrderLocal] = useState({});

  useEffect(() => {
    const storedOrder = JSON.parse(localStorage.getItem("order")) || {};
    setOrderLocal(storedOrder);

    const hasRefreshed = localStorage.getItem('hasRefreshed');
    if (!hasRefreshed) {
      localStorage.setItem('hasRefreshed', 'true');
      window.location.reload(); 
    }
  }, []);

 
  const groupedProducts = (orderLocal?.id || []).reduce((acc, id, index) => {
    if (!acc[id]) {
      acc[id] = {
        name: orderLocal.productName[index],
        quantity: 0,
        price: parseFloat(orderLocal.price[index]),
        address: orderLocal.address,
        note: orderLocal.note,
        email: orderLocal.email,
        payment: orderLocal.payment,
        firstName: orderLocal.firstName,
        lastName: orderLocal.lastName,
        mobile: orderLocal.mobile,
        store: orderLocal.store,
      };
    }
    acc[id].quantity += 1; 
    return acc;
  }, {});

  return (
    <>
      <h4 className="text-center my-5">
        {t.orderMsg}
      </h4>
      <div className="tf-page-cart-wrap my-5">
        <div className="tf-page-cart-item" id="orderTable">
          <div className="order-details">
            <h3 style={{color:"#000"}}>{t.paymentTitle}</h3>
            <form>
              <table className="tf-table-page-cart">
                <thead>
                  <tr>
                    <th>{t.orderTitle}</th>
                    <th>{t.orderQuantity}</th>
                    <th>{t.sort_price}</th>
                    <th>{t.payment1}</th>
                    <th>{t.paymentmethod}</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.entries(groupedProducts).map(
                    ([id, { name, quantity, price, address, payment }]) => (
                      <tr className="tf-cart-item file-delete " key={id}>
                        <td
                          data-label="Mehsul adi"
                          className="tf-cart-item_product "
                        >
                          <div className="cart-info my-2">
                            <a href="#" className="cart-title link">
                              {name}
                            </a>
                          </div>
                        </td>
                        <td
                          data-label="Miqdar"
                          className="tf-cart-item_quantity"
                        >
                          <div className="cart-quantity">{quantity}</div>
                        </td>
                        <td data-label="Qiymət" className="tf-cart-item_price">
                          <div className="cart-price">{price} ₼</div>
                        </td>
                        <td data-label="Ödəniş" className="tf-cart-item_total">
                          <div className="cart-total">{payment !== "cash" ? t.cash : t.onlinePayment}</div>
                        </td>
                        <td
                          data-label="Çatdırılma üsulu"
                          className="tf-cart-item_delivery"
                        >
                          <div className="cart-total">
                            {address !== "" ? address : t.radio1}
                          </div>
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </form>
          </div>
        </div>

        <div className="mobileTable">
        {Object.entries(groupedProducts).map(
      ([id, { name, quantity, price, address, payment }]) => (
        <>
        <div className="d-flex justify-content-between"><p>{t.orderTitle}</p>
        <p>{name.split(" (")[0]}</p>
        </div>
        <hr />
        <div className="d-flex justify-content-between"><p>{t.orderQuantity}</p>
        <p>{quantity}</p>
        </div>
        <hr />
        <div className="d-flex justify-content-between"><p>{t.sort_price}</p>
        <p>{price} ₼</p>
        </div>
        <hr />
        <div className="d-flex justify-content-between"><p>{t.payment1}</p>
        <p>{payment == "cash" ? t.cash : t.onlinePayment}</p>
        </div>
        <hr />
        <div className="d-flex justify-content-between"><p>{t.paymentmethod}</p>
        <p> {address !== "" ? address : t.radio1}</p>
        </div>
        </>
      )
    )}
        </div>
        <div className="tf-page-cart-footer mt-3">
          <div className="tf-cart-footer-inner">
            <div className="tf-page-cart-checkout">
              <div className="container" style={{ padding: "0" }}>
                <div className="row g-2">
                  {Object.values(groupedProducts).map(
                    ({
                      note,
                      firstName,
                      lastName,
                      email,
                      mobile,
                      quantity,
                      price,
                    }) => (
                      <React.Fragment key={`${firstName}-${lastName}`}>
                        <div className="d-flex justify-content-between my-2">
                          <h6 style={{ fontSize: "18px" }}>{t.name}</h6>
                          <h6 style={{ fontSize: "18px" }}>{firstName}</h6>
                        </div>
                        <div className="d-flex justify-content-between my-2">
                          <h6 style={{ fontSize: "18px" }}>{t.last}</h6>
                          <h6 style={{ fontSize: "18px" }}>{lastName}</h6>
                        </div>
                        <div className="d-flex justify-content-between my-2">
                          <h6 style={{ fontSize: "18px" }}>{t.email}</h6>
                          <h6 style={{ fontSize: "18px" }}>{email}</h6>
                        </div>
                        <div className="d-flex justify-content-between my-2">
                          <h6 style={{ fontSize: "18px" }}>{t.phone}</h6>
                          <h6 style={{ fontSize: "18px" }}>{mobile}</h6>
                        </div>
                        <div className="d-flex justify-content-between my-2">
                          <h6 style={{ fontSize: "18px" }}>{t.orderTotal}</h6>
                          <h6 style={{ fontSize: "18px" }}>
                            {(quantity * price).toFixed(2)} ₼
                          </h6>
                        </div>
                        <div>
                          <hr />
                          <h6 style={{ fontSize: "18px" }}>{t.orderNote} :</h6>
                          <h6 style={{ fontSize: "18px" }}>{note}</h6>
                        </div>
                      </React.Fragment>
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Order;
