import React, { useContext, useEffect, useState } from "react";
import BreadCrumb from "../others/BreadCrumb";
import translations from "../data/langData";
import { LanguageContext } from "../context/LangaugeContext";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
const Checkout = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [radio, setRadio] = useState("store");
  const [address, setAddress] = useState("");
  const [note, setNote] = useState("");

  // ERR MESSAGE
  const [nameErr, setNameErr] = useState(false);
  const [lastErr, setLastErr] = useState(false);
  const [phoneErr, setPhoneErr] = useState(false);
  const [emailErr, setEmailErr] = useState(false);
  const navigate = useNavigate();
  // PAYMENT
  const [payment, setPayment] = useState("cash");

  // ID PRICE NAME
  const [ids, setIds] = useState([]);
  const [prices, setPrices] = useState([]);
  const [productName, setProductName] = useState([]);

  const { language } = useContext(LanguageContext);
  const t = translations[language];
  const productLocal = JSON.parse(localStorage.getItem("cart")) || [];
  const totalPrice = productLocal.reduce(
    (total, item) => total + item.price * item.quantity,
    0
  );
  const handleRadioChange = (e) => {
    setRadio(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let isValid = true;

    const fieldsCheck = [
      { value: firstName, error: setNameErr },
      { value: lastName, error: setLastErr },
      { value: phone, error: setPhoneErr },
      { value: email, error: setEmailErr },
    ];

    fieldsCheck.forEach((item) => {
      if (item.value.trim() === "") {
        item.error(true);
        isValid = false;
      } else {
        item.error(false);
      }
    });

    if (isValid) {
      const formData = new FormData();
      formData.append("id", JSON.stringify(ids));
      formData.append("firstName", firstName);
      formData.append("lastName", lastName);
      formData.append("mobile", phone);
      formData.append("email", email);
      formData.append("store", radio); // 2 radioda burdadi store - address
      formData.append("adress", address);
      formData.append("note", note);
      formData.append("price", JSON.stringify(prices));
      formData.append("productName", JSON.stringify(productName));
      formData.append("payment", payment);

      axios
        .post("https://xor.az/api/order.php", formData)
        .then((res) => {
          Swal.fire({
            position: "top-center",
            icon: "success",
            title: `${t.alert}`,
            showConfirmButton: false,
            timer: 1500,
          });
          setFirstName("");
          setLastName("");
          setEmail("");
          setPhone("");
          setRadio("");
          setAddress("");
          setNote("");
          setIds("");
          setPayment("");
          localStorage.removeItem("cart");
          navigate("/order")

          const newOrderData = {
            id: ids,
            firstName,
            lastName,
            mobile: phone,
            email,
            store: radio,
            payment,
            address,
            note,
            price: prices,
            productName,
          };
          localStorage.setItem("order", JSON.stringify(newOrderData));
        })

        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    const expandedIds = productLocal.flatMap((item) =>
      Array(item.quantity).fill(item.id)
    );
    setIds(expandedIds);
    const prices = productLocal.map((i) => i.price);
    setPrices(prices);
    const names = productLocal.map((i) => i.name_show);
    setProductName(names);
  }, []);

  const handleRadioPayment = (e) => {
    setPayment(e.target.value);
  };
 

  
  return (
    <>
      <BreadCrumb title={t.checkout} />
      {productLocal.length == 0 ? (
        <h1 style={{ fontSize: "25px", height: "100px", textAlign: "center" }}>
          {t.ordermsg}
        </h1>
      ) : (
        <div className="content-wraper">
          <div className="container">
            <div className="checkout-details-wrapper">
              <form
                action={
                  payment === "payment"
                    ? "https://xor.az/payment.php"
                    : undefined
                }
                method={payment === "payment" ? "POST" : undefined}
                onSubmit={payment === "payment" ? undefined : handleSubmit}
              >
                <div className="row">
                  {payment == "payment" && (
                    <>
                      <input
                        type="hidden"
                        name="price"
                        id="price"
                        value={JSON.stringify(prices)}
                      />
                      <input
                        type="hidden"
                        name="productName"
                        id="price"
                        value={JSON.stringify(productName)}
                      />
                      <input
                        type="hidden"
                        name="id"
                        id="price"
                        value={JSON.stringify(ids)}
                      />
                      <input
                        type="hidden"
                        name="orderTotal"
                        id="price"
                        value={totalPrice.toFixed(1)}
                      />
                    </>
                  )}
                  <div className="col-lg-6 col-md-6">
                    <div className="billing-details-wrap">
                      <h3 className="shoping-checkboxt-title">{t.billing}</h3>
                      <div className="row">
                        <div className="col-lg-6">
                          <p className="single-form-row">
                            <label>
                              {t.name} <span className="required">*</span>
                            </label>
                            <input
                              type="text"
                              value={firstName}
                              name="firstName"
                              placeholder={`${t.name}`}
                              style={{
                                border: `1px solid ${nameErr ? "red" : ""}`,
                              }}
                              required
                              onChange={(e) => {
                                setFirstName(e.target.value);
                                setNameErr(false);
                              }}
                            />
                            {nameErr && (
                              <span className="invalid_message">
                                {t.invalid}
                              </span>
                            )}
                          </p>
                        </div>
                        <div className="col-lg-6">
                          <p className="single-form-row">
                            <label>
                              {t.last}
                              <span className="required">*</span>
                            </label>
                            <input
                              type="text"
                              value={lastName}
                              name="lastName"
                              placeholder={`${t.last}`}
                              style={{
                                border: `1px solid ${lastErr ? "red" : ""}`,
                              }}
                              onChange={(e) => {
                                setLastName(e.target.value);
                                setLastErr(false);
                              }}
                              required
                            />
                            {lastErr && (
                              <span className="invalid_message">
                                {t.invalid}
                              </span>
                            )}
                          </p>
                        </div>
                        <div className="col-lg-12">
                          <p className="single-form-row">
                            <label>{t.phone}</label>
                            <input
                              type="text"
                              name="mobile"
                              value={phone}
                              placeholder="+994"
                              style={{
                                border: `1px solid ${phoneErr ? "red" : ""}`,
                              }}
                              onChange={(e) => {
                                setPhone(e.target.value);
                                setPhoneErr(false);
                              }}
                              required
                            />
                            {phoneErr && (
                              <span className="invalid_message">
                                {t.invalid}
                              </span>
                            )}
                          </p>
                        </div>
                        <div className="col-lg-12">
                          <div className="single-form-row">
                            <label>
                              {t.email} <span className="required">*</span>
                            </label>
                            <input
                              type="email"
                              value={email}
                              name="email"
                              placeholder={`${t.email}`}
                              style={{
                                border: `1px solid ${emailErr ? "red" : ""}`,
                              }}
                              onChange={(e) => {
                                setEmail(e.target.value);
                                setEmailErr(false);
                              }}
                              required
                            />
                            {emailErr && (
                              <span className="invalid_message">
                                {t.invalid}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-12 mt-1">
                          <label>{t.radio}</label>
                          <br />
                          <div className="d-flex justify-content-start align-items-center">
                            <input
                              id="store-radio"
                              type="radio"
                              name="radio"
                              onChange={handleRadioChange}
                              value="store"
                              checked={radio === "store"}
                              className="radio"
                            />
                            <label
                              style={{ cursor: "pointer" }}
                              htmlFor="store-radio"
                              className="ms-2"
                            >
                              {t.radio1}
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="d-flex justify-content-start align-items-center">
                            <input
                              id="store-address"
                              type="radio"
                              name="radio"
                              onChange={handleRadioChange}
                              value="address"
                              checked={radio === "address"}
                              className="radio"
                            />
                            <label
                              style={{ cursor: "pointer" }}
                              htmlFor="store-address"
                              className="ms-2"
                            >
                              {t.radio2}
                            </label>
                          </div>
                        </div>
                        {radio == "address" && (
                          <div className="col-lg-12 mt-3">
                            <div className="single-form-row">
                              <label>
                                {t.address} <span className="required">*</span>
                              </label>
                              <input
                                type="text"
                                value={address}
                                name="address"
                                placeholder={`${t.address1}`}
                                onChange={(e) => {
                                  setAddress(e.target.value);
                                }}
                              />
                            </div>
                          </div>
                        )}
                        <div className="col-lg-12 mt-3">
                          <p className="single-form-row m-0">
                            <label>{t.orderNote}</label>
                            <textarea
                              placeholder={`${t.orderDesc}`}
                              className="checkout-mess"
                              rows={2}
                              cols={5}
                              name="note"
                              value={note}
                              onChange={(e) => {
                                setNote(e.target.value);
                              }}
                            />
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="your-order-wrapper">
                      <h3 className="shoping-checkboxt-title">{t.orders}</h3>

                      <div className="your-order-wrap">
                        <div className="your-order-table table-responsive">
                          <table>
                            <thead>
                              <tr>
                                <th className="product-name">{t.product}</th>
                                <td className="product-total">{t.total}</td>
                              </tr>
                            </thead>
                            {productLocal.map((item) => (
                              <tbody>
                                <tr className="cart_item">
                                  <td className="product-name">
                                    {item.name_show} {item.memory + "GB"}{" "}
                                    {item.value}{" "}
                                    <strong className="product-quantity">
                                      {" "}
                                      × {item.quantity}
                                    </strong>
                                  </td>
                                  <td className="product-total">
                                    <span className="amount">
                                      {item.price} ₼
                                    </span>
                                  </td>
                                </tr>
                              </tbody>
                            ))}
                            <tfoot>
                              <tr className="order-total">
                                <td>{t.orderTotal}</td>
                                <td>
                                  <strong>
                                    <span className="amount">
                                      {totalPrice.toFixed(1)} ₼
                                    </span>
                                  </strong>
                                </td>
                              </tr>
                              <br />
                              <tr className="order-total">
                                <h6 className="fs-6">{t.payment}</h6>
                              </tr>
                              <tr className="">
                                <input
                                  type="radio"
                                  id="cash"
                                  name="cash"
                                  value="cash"
                                  checked={payment === "cash"}
                                  onChange={handleRadioPayment}
                                  className="radio"
                                />
                                <label
                                  htmlFor="cash"
                                  className="my-2 ms-2"
                                  style={{ cursor: "pointer" }}
                                >
                                  {t.paymentDoor}
                                </label>
                                <br />
                                <input
                                  type="radio"
                                  id="online"
                                  value="payment"
                                  name="payment"
                                  checked={payment === "payment"}
                                  onChange={handleRadioPayment}
                                  className="radio"
                                />
                                <label
                                  htmlFor="online"
                                  className="ms-2"
                                  style={{ cursor: "pointer" }}
                                >
                                  {t.paymentOnline}
                                </label>
                              </tr>
                            </tfoot>
                          </table>
                        </div>
                        <div className="payment-method">
                          <div className="order-button-payment">
                            <input type="submit" value={`${t.placeOrder}`} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Checkout;
