import React, { useContext, useState } from "react";
import { Helmet } from "react-helmet";
import BreadCrumb from "../others/BreadCrumb";
import { LanguageContext } from "../context/LangaugeContext";
import translations from "../data/langData";
const Contact = () => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  return (
    <>
      <Helmet>
        <title>{t.contact}</title>
      </Helmet>
      <BreadCrumb title={t.contact}/>
      <div className="content-wraper pb-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 col-sm-12">
              <div className="contact-form">
                <div className="contact-form-info">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d3613.9382358675457!2d49.8214394735797!3d40.38785156560355!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2saz!4v1721737570842!5m2!1sen!2saz"
                    
                    
                    style={{border:"none",width:"100%",height:"450px"}}
                    allowfullscreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
              </div>
            </div>
            <div className="col-lg-5 col-sm-12">
              <div className="contact-infor">
                <div className="contact-title">
                  <h3>{t.contact}</h3>
                </div>
                <div className="contact-dec">
                  <p>
                    {t.contactDesc}
                  </p>
                </div>
                <div className="contact-address">
                  <ul>
                    <li>
                      <i className="fa fa-fax"> </i> {t.address} : Baku, Yasamal {t.district}, Izmir {t.street} 9
                    </li>
                    <li>
                      <i className="fa fa-phone">&nbsp;</i>*8989
                    </li>
                    <li>
                      <a href="mailto:info@xor.az"><i className="fa fa-envelope-o">&nbsp;</i> info@xor.az</a>
                    </li>
                  </ul>
                </div>
                <div className="work-hours">
                  <h3>
                    <strong>{t.working}</strong>
                  </h3>
                  <p>
                    <strong>{t.monday}</strong>: &nbsp; {t.am}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
