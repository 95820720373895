import img1 from "../assets/image/xor1.webp"
import img2 from "../assets/image/xor2.webp"
import img3 from "../assets/image/xor3.webp"

export const blogdata = [
    {
        id:0,
        img:img1,
        title:"Azərbaycanda XOR mağazasının açılışı.",
        title1:"Opening of XOR Store in Azerbaijan.",
        desc:"İyulun 24-də Azərbaycanın paytaxtı Bakıda mobil texnologiya sahəsində böyük hadisə baş verdi: XOR markalı telefonlar üçün flaqman mağazasının açılışı.",
        desc1:"On July 24, a significant event in mobile technology took place in Baku, the capital of Azerbaijan: the opening of the flagship store for XOR brand phones.",
        img1:img2,
        img2:img3
    }
]