import React, { useContext } from "react";
import BreadCrumb from "../others/BreadCrumb";
import translations from "../data/langData";
import { LanguageContext } from "../context/LangaugeContext";
import { Helmet } from "react-helmet";
import { blogdata } from "../data/blogdata";
import { NavLink } from "react-router-dom";
import slugify from "react-slugify";
const Blog = () => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  return (
    <>
      <Helmet>
        <title>{t.blogs}</title>
      </Helmet>
      <BreadCrumb title={t.blogs} />
      <div className="content-wraper">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="blog-wrapper">
                <div className="row blog-wrap-col-3">
               {
                blogdata.map((fd)=>(
                  <div className="col-lg-6">
                  <div className="single-blog-area mb--40">
                    <div className="blog-image">
                      <NavLink to={`/blog/${slugify(language == "en" ? fd.title1 : fd.title)}`} onClick={()=>window.scrollTo({top:0})}>
                        <img src={fd.img} className="img-fluid" alt="" />
                      </NavLink>
                    </div>
                    <div className="blog-contend">
                      <h3>
                        <a href="#">{language == "en" ? fd.title1 : fd.title}</a>
                      </h3>
                      <div className="blog-date-categori">
                        <ul>
                          <li>
                            <a href="#">
                              <i className="fa fa-comments"></i> 24.07.2024{" "}
                            </a>
                          </li>
                        </ul>
                      </div>
                      <p>
                      {language == "en" ? fd.desc1 : fd.desc}
                      </p>
                      <div className="mt-30 blog-more-area">
                        <NavLink to={`/blog/${slugify(language == "en" ? fd.title1 : fd.title)}`} onClick={()=>window.scrollTo({top:0})} className="blog-btn btn">
                          {t.readMore}
                        </NavLink>
                        <ul className="social-icons">
                          <li>
                            <a href="#">
                              <i className="fa fa-facebook"></i>
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fa fa-twitter"></i>
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fa fa-dribbble"></i>
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fa fa-google-plus"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                ))
               }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Blog;
