import React from "react";
import banner1 from "../assets/image/banner1.jpg"
import banner2 from "../assets/image/banner2.jpg"
import { NavLink } from "react-router-dom";

const HomeBanner = () => {
  return (
    <div className="banner-area-two">
  <div className="container-fluid plr-40">
    <div className="row">
      <div className="col-lg-6 col-md-6">
        <div className="single-banner-two mt--30">
          <NavLink to="/collections">
            <img src={banner1} alt />
          </NavLink>
        </div>
      </div>
      <div className="col-lg-6 col-md-6">
        <div className="single-banner-two mt--30">
          <NavLink to="/collections">
            <img src={banner2} alt />
          </NavLink>
        </div>
      </div>
    </div>
  </div>
</div>

  );
};

export default HomeBanner;
