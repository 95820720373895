export const addItem = (item, quantity = 1) => {
    let addCart = JSON.parse(localStorage.getItem('cart')) || [];

    const itemIndex = addCart.findIndex(cartItem => cartItem.id === item.id);
  
    if (itemIndex > -1) {
      addCart[itemIndex].quantity += quantity;
    } else {
      addCart.push({ ...item, quantity });
    }
    
    localStorage.setItem('cart', JSON.stringify(addCart));
        
    window.dispatchEvent(new Event('cartUpdated'));
  };
  