import React, { useContext, useEffect, useState } from "react";
import xor_logo from "../assets/image/xor-logo.png";
import { LanguageContext } from "../context/LangaugeContext";
import translations from "../data/langData";
import { NavLink } from "react-router-dom";
import axios from "axios";
import slugify from "react-slugify";

const Footer = () => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  const year = new Date();
  const currentYear = year.getFullYear();
  const [data, setData] = useState([]);
  useEffect(() => {
    axios
      .get("https://myshops.az/apk/app_prod.php?q=xor")
      .then((res) => {
        const resData = res.data.slice(-4);
        setData(resData);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <footer className="footer-area mt--50">
        <div className="footer-top pt--50 section-pb">
          <div className="container-fluid">
            <div className="row ms-4 footer_row">
              <div className="col-lg-4 col-md-6 col-6 ">
                <div className="footer-info-area footer_margin ">
                  <div className="footer-logo">
                    <a href="https://xor.az/>">
                      <img style={{ width: "75%" }} src={xor_logo} alt="" />
                    </a>
                  </div>
                  <div className="desc_footer">
                    <p>
                      <i className="fa fa-home"></i>{" "}
                      <span>
                        {" "}
                        Baku, Yasamal {t.district}, Izmir {t.street} 9
                      </span>{" "}
                    </p>
                    <p>
                      <i className="fa fa-phone"></i> <span> *8989</span>{" "}
                    </p>
                    <p>
                      <i className="fa fa-envelope-open-o"></i>{" "}
                      <span>
                        {" "}
                        <a href="mailto:info@xor.az">info@xor.az</a>
                      </span>{" "}
                    </p>
                    <div className="link-follow-footer">
                      <ul className="footer-social-share ">
                        <li className="d-flex">
                          <a
                            href="https://www.instagram.com/xor.inc.az/"
                            target="_blank"
                            title="Instagram"
                          >
                            <i className="fa fa-instagram" />
                          </a>
                          <p className="ms-2">
                            <a
                              href="https://www.instagram.com/xor.inc.az/"
                              target="_blank"
                            >
                              xor.inc.az
                            </a>
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              
              <div className="col-lg-4 col-md-6 col-6 ">
                <div className="row">
                  <div className="col-lg-6 col-md-6 ">
                    <div className="footer-info-area">
                      <div className="footer-title">
                        <h3>{t.pages}</h3>
                      </div>
                      <div className="desc_footer">
                        <ul>
                          <li>
                            <NavLink
                              to="/collections"
                              onClick={() => window.scrollTo({ top: 0 })}
                            >
                              {t.collections}
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              to="/about"
                              onClick={() => window.scrollTo({ top: 0 })}
                            >
                              {" "}
                              {t.about}
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              to="/blog"
                              onClick={() => window.scrollTo({ top: 0 })}
                            >
                              {" "}
                              {t.blogs}
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              to="/contact"
                              onClick={() => window.scrollTo({ top: 0 })}
                            >
                              {" "}
                              {t.contact}
                            </NavLink>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 footer_del">
                    <div className="footer-info-area">
                      <div className="footer-title">
                        <h3 className="t_collections">{t.collections_f}</h3>
                      </div>
                      <div className="desc_footer">
                        <ul>
                          {data.map((item) => (
                            <li>
                              <NavLink
                                to={`/${slugify(
                                  `${item.name_show}-${item.memory + "GB"}-${
                                    item.value
                                  }`
                                ).toLowerCase()}`}
                                onClick={() => window.scrollTo({ top: 0 })}
                              >
                                {" "}
                                {item.name_show.split(" (")[0]} {item.value}
                              </NavLink>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-12 footer_del">
                <div className="footer-info-area">
                  <div className="footer-title">
                    <h3>{t.join} </h3>
                  </div>
                  <div className="desc_footer">
                    <div className="input-newsletter">
                      <input
                        name="email"
                        className="input_text"
                        value=""
                        placeholder={t.yourEmail}
                        type="text"
                      />
                      <button className="btn-newsletter">
                        <i className="fa fa-paper-plane-o"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>



        <div className="footer-buttom">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-7">
                <div className="copy-right">
                  <p>
                    ©️ {currentYear} <a href="https://xor.az/">{t.xorAz}</a>.
                    {t.copyRight}
                  </p>
                </div>
              </div>
              <div className="col-lg-6 col-md-5">
                <div className="payment">
                  <img src="assets/images/icon/1.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
