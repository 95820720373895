import React, { useContext } from 'react'
import { Helmet } from 'react-helmet'
import HomeSlider from '../../pages/HomeSlider'
import HomeBanner from '../../pages/HomeBanner'
import translations from '../../data/langData'
import { LanguageContext } from '../../context/LangaugeContext'

const Home = () => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];

  return (
  <>
   <Helmet>
    <title>XOR - Luxury mobile phone | Azerbaijan</title>
  </Helmet>
  <HomeSlider/>
  <HomeBanner/>
  </>
  )
}

export default Home
